import { apiUrlPath } from '@/constants/apiUrl';
import { defaultLanguage } from '@/constants/localization/localization.constans';
import Cookies from '@/helpers/cookies';
import { $t } from '@/utils/i18n';
import axios, { AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { serviceOptions } from '@/api/rest/serviceTransport';
import * as AxiosLogger from 'axios-logger';

const isProd = process.env.NODE_ENV === 'production';

export default class Api {
  private static currentInstance: Api;

  private httpClient: AxiosInstance = null as any;

  private token: string | any = null;

  private timerId: any = null;

  static get instance(): Api {
    if (!this.currentInstance) {
      this.currentInstance = new Api();
      this.currentInstance.register()
    } 
    return this.currentInstance;
  }

  public reRegister() {
    this.register()
  }

  private async register() {
    console.info('Api register')
    this.httpClient = axios.create({
      baseURL: apiUrlPath.url + '/',
    });
    console.info('Api backend path - ' + apiUrlPath.url)
    if (!isProd) {
      this.httpClient.interceptors.request.use(AxiosLogger.requestLogger);
    }
    this.httpClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        const { config, request } = error;
        const { url } = config;

        this.processErrorResponse(error);
        console.error('Please, check', [url, request, error.response, error]);
        return Promise.reject(error);
      }
    );
    axiosRetry(this.httpClient, {
      retries: 3,
      retryDelay: (retryCount:any) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => {
        return (
          error?.response?.status === 503 ||
          error?.response?.status === 504 ||
          error?.response?.status === 598 ||
          error?.response?.status === 599 ||
          error?.response?.status === 408
        );
      },
    });

    const language = localStorage.getItem('language');

    language
      ? (this.httpClient.defaults.headers!['Accept-Language'] = language)
      : (this.httpClient.defaults.headers!['Accept-Language'] = defaultLanguage);

    const date = new Date();

    this.httpClient.defaults.headers['Timezone'] = (-date.getTimezoneOffset() / 60).toString();

    serviceOptions.axios = this.httpClient;
  }

  authorize(token: string) {
    this.token = token;
    this.httpClient.defaults.headers['Authorization'] = `Bearer ${token}`;
    const date = new Date();
    this.httpClient.defaults.headers['Timezone'] = (-date.getTimezoneOffset() / 60).toString();
    serviceOptions.axios = this.httpClient;
  }

  setLanguage(language: string) {
    //TODO: проверить почему не вызываеться
    this.httpClient.defaults.headers['Accept-Language'] = language;
  }
  
  private processErrorResponse(result: any) {
    const date = new Date();
    
    if (result.response != null) {
      if (result.response.status === 401) {
        date.setDate(date.getDate() + 1);
        Cookies.set('login-message', $t('MoreRightsRequired'), {
          expired: date,
        });
      } else if (result.response.status === 500) {
        if (result.response.data == null || result.response.data.length === 0) result.response.data = $t('ServerError');
      }
      return;
    } else if (result.message === 'Network Error') {
      Cookies.set('login-message', $t('ServerConnectionError'), {
        expired: date,
      });
      if (result?.response?.data == null || result?.response?.data?.length === 0) {
        result.response = { data: $t('ServerConnectionError') };
      }
      return;
    }
  }
}
