import { defineComponent, } from 'vue';
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@/utils/i18n-validators";
import DataView from "primevue/dataview";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber"
import { $t } from '@/utils/i18n';
import router from '@/router';
import store from '@/store';
import { UserDto } from '@/api/rest/serviceTransport';
import { BillingService } from '@/api/rest/restClient';
import Formatter from '@/helpers/formatters';

export default defineComponent({
    components:{
      DataView,
      Button,
      InputNumber
    },
    name: 'PaySuccess',
    props:{
        ms : String
      },
    setup (){
        return { v$: useVuelidate() }

    },
    validations () {
        return {
            email: { required, email },
        }
    },
    data(){
      return {
        isError : false,
        progress : true,
        currency : '$',
        amount : 0,
        balans : 0,
        seconds : 30 as number,
        timerID : 0,
        currentUser : store.getters.currentUser as UserDto
      }
    },
    mounted() {
      this.progress = false
      this.onEnter()
      this.timerID = setInterval(this.updateTime, 1000);  
    },
    computed:{
      buttonTitle(){
        return $t('Button.Ok') + '(' + this.seconds +')'
      }
    },
    methods: {
      formatDate(value: Date ) {
        return new Date(value).toLocaleDateString($t('LocaleString'), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
      },
      formatTime(value: Date ) {
        return new Date(value).toLocaleTimeString($t('LocaleString'));
      },
      formatFullDate(value: Date ) {
        return (value == null || value == undefined) ? 'Нет данных' : 
          this.formatDate(value) + '\n' + this.formatTime(value)
      },
      formatSize(size : number) {
        var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) as unknown as number * 1 + ' ' + [$t('Size.byte'), $t('Size.kilobyte'), $t('Size.megabyte'), $t('Size.gigabyte'), $t('Size.terabyte')][i];
      },
      formatSeconds(seconds : number) {
        var str = new Date(seconds * 1000).toISOString()
        let result = str.match(/([1-9]{1,2}):(\d{2}):(\d{2})|([1-9]{1,2}):(\d{2})|([0]{2}):([1-9]{1})([0]{1})|([0]{2}):([0]{1})([1-9]{1})/g);
        return  result ? result[0] : 'n/a'
      },
      formatAmount(seconds) {
        Formatter.formatAmountSeconds(seconds)
      },
      formatAmmountStr(minutes : number){
        if(minutes < 60) 
          return $t('Time.minutes')
        else if ((minutes / 60) == 1) return $t('Time.h1')
        else if ( Math.floor(minutes / 60) <= 4) return $t('Time.h24')
        else if ((minutes % 60) > 0 && Math.floor(minutes / 60) > 4) return $t('Time.h24')
        else return $t('Time.h5')
      },
      toggleError(err : string){
              this.$toast.add({severity:'error', summary: $t('Error'), detail:err, life: 3000});  
      },
      updateTime(){
        if(this.seconds < 2){
          this.ok()
        }
        this.seconds -= 1  
      },
      ok(){
        clearTimeout(this.timerID)
        router.push('/')
      },
      onEnter(){
        if(this.$route.query.InvId){
          BillingService.getPayResult({orderId : this.$route.query.InvId})
            .then(data => {
              this.amount = data.amount
              this.currency = data.currency == 'USD' ? '$' : '₽'
            })
            .catch(err => {
              this.$toast.add({severity:'error', summary: $t('Error'), detail:err?.response?.data, life: 3000});  
            })
        }
      }
    }
  })
