import { email, required, minLength } from "@/utils/i18n-validators";
import { helpers, sameAs } from "@vuelidate/validators"
import { defineComponent, reactive, ref } from 'vue'
import { useVuelidate } from "@vuelidate/core"
import InputText from "primevue/inputtext"
import Dialog from "primevue/dialog"
import Password from "primevue/password"
import Button from "primevue/button"
import Checkbox from "primevue/checkbox"
import Divider from "primevue/divider"
import {$t, i18n} from '@/utils/i18n'
import ImageForm from '@/components/ImageForm/ImageForm.vue'
import HeaderNavigator from '@/components/HeaderNavigator/HeaderNavigator.vue'
import CountrySelector from '@/components/CountrySelector/CountrySelector.vue'
import { AuthenticationService } from "@/api/rest/restClient";

export default defineComponent({
    components:{
        Dialog,
        Divider,
        Password,
        Button,
        InputText,
        Checkbox,
        ImageForm,
        HeaderNavigator,
        CountrySelector
    },
    name:'Register',
    setup() { 
        return { v$: useVuelidate() }
    },
    validations () {
        return {
            email: { required, email },
            password: { 
                password : { required, minLength: minLength(8) },
                confirm : { required, sameAs: helpers.withMessage( this.sameas, sameAs(this.password.password))}
            },
            name: { required },
            code: { required },
            accept: { required }
        }
    },
    data() {
        return {
            name: '',
            code: '',
            countryCode : '',
            email: '',
            password:{
                password : '',
                confirm : ''
            },
            error: '',
            country : null,
            accept: null,
            submitted: false,
            countries: null,
            showMessage: false,
            showProgress: false,
            isError : false,
            seconds : 30 as number
        }
    },
    created() {
    },
    mounted() {
    },
    computed: {
        suggestionList: object => {
            return i18n.global.messages[i18n.global.locale]['SuggestionsList']
        },
        weakLabel: () => $t('PasswordLabels.weakLabel'),
        mediumLabel : () => $t('PasswordLabels.mediumLabel'),
        strongLabel : () => $t('PasswordLabels.strongLabel'),
        submit : () => $t('Submit'),
        buttonTitle(){ 
            return $t('Button.TryItNow') + '(' + this.seconds +')'
          },
        sameas : () => $t('validations.sameAs'),
        isConfirm(){return  this.password.password != "" && (this.password.password == this.password.confirm)}
    },
    methods: {
        handleSubmit(isFormValid) {
            this.submitted = true;
            if (!isFormValid) {
                return;
            }
            this.register();
        },
        toggleDialog(err : string) {
            this.error = err
            this.showProgress = false
            this.$toast.add({severity: 'error', summary: $t('Error'), detail: err});
        },
        register: function () {
            this.showProgress = true
            let registerDto = {
                email: this.email,
                password: this.password.password,
                fullName: this.name,
                country: this.code.code
                };
            console.log(registerDto);
            AuthenticationService.registerUser({ body: registerDto })
                .then(data => this.processRegisterResult(data as String))
                .catch(err => this.processRegisterError(err?.response?.data))
        },
        processRegisterResult(data: String): void {
            this.showProgress = false
            this.isError = false
            this.message = $t('Register.Success')
            this.showMessage = true
        },
        processRegisterError(err: String): void {
            this.showProgress = false
            this.isError = true
            this.message = err
            this.showMessage = true
            this.timer = setInterval(this.updateTime, 1000)
        },
        resetForm() {
            this.name = '';
            this.email = '';
            this.password = '';
            this.accept = null;
            this.submitted = false;
            this.showProgress = false
            this.isError = false
            this.showMessage = false
            if(this.timer){
                clearTimeout(this.timer)
                this.seconds = 30
            }
        },
        updateTime(){
            if(this.seconds < 2){
              this.resetForm()
            }
            this.seconds -= 1  
        },
    }
})