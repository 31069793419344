
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Wrap',
  data() {
    return {
    };
  },
  created(){
    
  },
});
