import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store/'
import HomeView from '../views/HomeView.vue'
import User from '../components/User/User.vue'
import RegisterConfirm from '../components/RegisterConfirm/RegisterConfirm.vue'
import ResetPassword  from '../components/ResetPassword/ResetPassword.vue'
import Api from '@/api/api'
import { AuthenticationService, UserService } from '@/api/rest/restClient'
import { MutationTypes } from '@/store/mutations'
import { UserDto } from '@/api/rest/serviceTransport'
import { apiUrlPath } from '@/constants/apiUrl'
import { ICurrentUser } from '@/interfaces/local-interfaces/authentication/user-dto'
import LoginView from '@/views/LoginView.vue'
import Login from '@/components/Login/Login.vue'
import Register from '@/components/Register/Register.vue'
import Billing from '@/components/Billing/Billing.vue'
import Account from '@/components/Account/Account.vue'
import PaySuccess from '@/components/PaySuccess/PaySuccess.vue'
import PayFail from '@/components/PayFail/PayFail.vue'


const getRuntimeConfig = async () => {
  const runtimeConfig = await fetch('/config.json');
  let config = await runtimeConfig.json();
  let url = config['BackendUrl'];
  apiUrlPath.url = url;
  apiUrlPath.urlBase = url;
  Api.instance.reRegister();
};

const checkToken = async (to, from, next) => {
  var token = to.query['token']
  console.info('check token token - ' + token)
  var res = false
  if(token){
    await getRuntimeConfig()
    .then(() =>{
      console.info('check tocken init config')
      AuthenticationService.loginByToken({token})
      .then((data)=>{
        console.info('check tocken token auth')
        const user = data as ICurrentUser;
        if (user.token) {
          Api.instance.authorize(user.token as string)
          store.commit(MutationTypes.SET_TOKEN, { token: user.token as string, isSession: true });
          UserService.getCurrentUser()
              .then((result) => {
                console.info('check tocken get user')
                  store.commit(MutationTypes.SET_CURRENT_USER, result as UserDto)
                  const minutes = to.query['minutes']
                  if(minutes){
                    router.push({ name : 'billing-ms', params: {ms: minutes}})     
                  }
                  else{
                    router.push({name : 'billing'})
                  }
                  res = true
              })
              .catch((err) => {
                  console.error(err?.response?.data as string);
              })
          }
        })
        .catch((err) => {
          console.error(err?.response?.data as string)
        })
      })
    .catch((err) =>{
      console.error(err)
    })
  }
  return res
};
const ifAuthenticated = async (to, from, next)=>{
  console.info('ifAuthenticated')
  if(store.getters.isAuthenticated){
    next()    
  }
  else{
    if(!await checkToken(to, from, next)){
      next('login')
    }
  }
}

const ifNotAuthenticated = (to, from, next)=>{
  console.info('ifNotAuthenticated')
  if(!store.getters.isAuthenticated){
    next()    
  }
  else{
    next('/')
  }
}




const routes: Array<RouteRecordRaw> = [
  {
    beforeEnter : ifAuthenticated,
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        name : 'user',
        path: '',
        component: User,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            name : 'account',
            path: '',
            component: Account,
            meta: {
              requiresAuth: true,
            },
          },
          {
            name : 'billing',
            path : 'billing',
            component: Billing,
            meta: {
              requiresAuth: true,
            }
          },
          {
            name : 'billing-ms',
            path : 'billing/:ms',
            props: true,
            component: Billing,
            meta: {
              requiresAuth: true,
            },
          },
          {
            name : 'paysuccess',
            path: 'billing/success',
            component: PaySuccess,
            meta: {
              requiresAuth: true,
            },
          },
          {
            name : 'payfail',
            path: 'billing/fail',
            component: PayFail,
            meta: {
              requiresAuth: true,
            },
          },
        ]
      }
    ]
  },
  {
    beforeEnter : ifNotAuthenticated,
    path: '/login',
    name: 'loginview',
    component: LoginView,
    meta: { 
      guest: true
    },
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
        meta: { 
          guest: true
        }
      },
      {
        path: '/resetpassword',
        name: 'resetpassword',
        component: ResetPassword,
        meta: { 
          guest: true
        }
      },
      {
        path: '/register',
        name: 'register',
        component: Register,
        meta: { 
          guest: true
        }
      },
      {
        name: 'registerconfirm',
        path: '/registerconfirm',
        props: route => ({ token: route.query.token }),
        meta: { 
          guest: true
        },
        component: RegisterConfirm
      },
    ]
  },
  // {
  //   path: '*',
  //   name: 'notfound',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/NotFoundView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(''),
  routes
})



router.beforeEach((to, from, next) => {
  const lang = (to.query['lang']??store.state.localization.language) as string
  const locale = lang.toLowerCase().includes('ru') ? 'ru-RU' : 'en-GB'
  store.dispatch('SET_LANGUAGE', locale)
  next()
})
export default router
