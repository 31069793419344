
  import { defineComponent } from '@vue/runtime-core'
  import Header from '@/components/Header/Header.vue'
  import Footer from '@/components/Footer/Footer.vue'
  import Wrap from '@/components/Wrap/Wrap.vue'
  export default defineComponent({
    components: {
      Header,
      Footer,
      Wrap
    },
    data(){
      return this
    },
     beforeCreate() {
  }});
