import { ActionTree, createStore, GetterTree, MutationTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';
import { Getters, GettersTypes } from './getters';
import { AcionsTypes, Action } from './actions';
import { VueCookieNext } from 'vue-cookie-next';
import { storeModules } from './store-manager/store.modules';
import Api from '@/api/api';
import { LoginInDto, UserDto } from '@/api/rest/serviceTransport';
import { LanguageCode } from '@/enums/localization/localization.enum';
import localization, { IStateLocalizationTypes } from './localization/localization';

export const tokenKey = 'token';
const userKey = 'user';
const isSessionKey = 'isSession';
const costPerMinute = 'costPerMinute';

export interface IRootState {
  currentUser: UserDto | null;
  token: string | null;
  localization: IStateLocalizationTypes;
  isSession: boolean;
  costPerMinute: number;
}

const state: IRootState = {
  token: localStorage.getItem(tokenKey) ?? sessionStorage.getItem(tokenKey) ?? null,
  localization: {language : localStorage.getItem('language') ?? LanguageCode.en_GB},
  currentUser: (JSON.parse(localStorage.getItem(userKey)!) as UserDto) || (JSON.parse(sessionStorage.getItem(userKey)!) as UserDto) || null,
  isSession: (JSON.parse(localStorage.getItem(isSessionKey)!) as boolean) || false,
  costPerMinute: (JSON.parse(localStorage.getItem(costPerMinute)!) as number) || (JSON.parse(sessionStorage.getItem(costPerMinute)!) as number) || 0.15,
};

const getters: GetterTree<IRootState, IRootState> & GettersTypes = {
  [Getters.isAuthenticated]: (state: IRootState) => !!state.token,
  [Getters.currentUser]: (state: IRootState) => state.currentUser,
  [Getters.token]: (state: IRootState) => state.token,
  [Getters.localization]: (state: IRootState) => state.localization,
  [Getters.isSession]: (state: IRootState) => state.isSession,
  [Getters.costPerMinute]: (state: IRootState) => state.costPerMinute,
};

const actions: ActionTree<IRootState, IRootState> & AcionsTypes = {};

const mutations: MutationTree<IRootState> & Mutations = {
  [MutationTypes.LOGOUT](state: IRootState) {
    state.token = null;
    state.currentUser = null;
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(userKey);
    sessionStorage.removeItem(tokenKey);
    sessionStorage.removeItem(userKey);
    VueCookieNext.keys().forEach((cookie:any) => {
      return VueCookieNext.removeCookie(cookie);
    });
    localStorage.clear();
    sessionStorage.clear();
    location.reload();
  },
  [MutationTypes.SET_CURRENT_USER](state: IRootState, user: UserDto): void {
    state.currentUser = user;
    const setUser = JSON.stringify(user);
    if (!state.isSession) {
      localStorage.setItem(userKey, setUser);
    } 
    else {
      sessionStorage.setItem(userKey, setUser);
    }
  },
  [MutationTypes.SET_TOKEN](state: IRootState, data: { token: string; isSession: false }) {
    if (!data) return;
    if (!data.isSession || data.isSession == undefined || data.isSession == null) data.isSession = false;
      localStorage.setItem(isSessionKey, JSON.stringify(data.isSession as boolean));
      state.isSession = data.isSession;
      state.token = data.token;
      Api.instance.authorize(data.token);
      if (!data.isSession) {
        localStorage.setItem(tokenKey, data.token);
      } 
      else {
        sessionStorage.setItem(tokenKey, data.token);
      }
  },
  [MutationTypes.SET_COST](state: IRootState, data: { cost: number; isSession: false }) {
    if (!data) return;
    if (!data.isSession || data.isSession == undefined || data.isSession == null) data.isSession = false;
    state.costPerMinute = data.cost;
    if (!data.isSession) {
      localStorage.setItem(costPerMinute, JSON.stringify(data.cost as number));
    } 
    else {
      sessionStorage.setItem(costPerMinute, JSON.stringify(data.cost as number));
    }
  },
};

const store = createStore({
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  modules: storeModules,
});

export default store;
