import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf21ae32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "title flex pb-2 justify-content-center" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "product-grid-item-cotent justify-content-center" }
const _hoisted_6 = { class: "product-count flex align-items-center justify-content-center" }
const _hoisted_7 = { class: "product-grid-item-content justify-content-center" }
const _hoisted_8 = { class: "product-item flex align-items-center justify-content-center" }
const _hoisted_9 = { class: "product-grid-item-bottom justify-content-center" }
const _hoisted_10 = { class: "product-price flex align-items-center justify-content-center" }
const _hoisted_11 = { class: "flex justify-content-center align-content-center" }
const _hoisted_12 = { class: "cost" }
const _hoisted_13 = { class: "flex align-items-center justify-content-center pr-5" }
const _hoisted_14 = { class: "min flex align-items-center justify-content-center pr-5" }
const _hoisted_15 = { class: "min flex align-items-center justify-content-center pl-5" }
const _hoisted_16 = { class: "flex align-items-center justify-content-center pl-5" }
const _hoisted_17 = { class: "cost" }
const _hoisted_18 = { class: "pay flex flex-wrap justify-content-center pt-8" }
const _hoisted_19 = { class: "to-pay flex align-items-center justify-content-center" }
const _hoisted_20 = { class: "ammount flex align-items-center justify-content-center" }
const _hoisted_21 = { class: "flex flex-wrap justify-content-start align-content-start" }
const _hoisted_22 = { class: "cost" }
const _hoisted_23 = { class: "flex justify-content-center align-content-center pt-5 pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataView = _resolveComponent("DataView")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('Billing.Header')), 1)
    ]),
    _createElementVNode("div", {
      class: "billing-wrapper",
      onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.setActive(0)), ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('Billing.Title')), 1)
      ]),
      _createVNode(_component_DataView, {
        value: _ctx.tariffs,
        layout: "grid",
        rows: 9,
        class: "product-grid",
        "data-key": "id"
      }, {
        grid: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["product-grid-item", { active : slotProps.data.id == _ctx.activeId}]),
              onClick: _withModifiers(($event: any) => (_ctx.setActive(slotProps.data.id)), ["stop"])
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatAmmount(slotProps.data.minutes)), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formatAmmountStr(slotProps.data.minutes)), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, "$" + _toDisplayString(slotProps.data.amount), 1)
              ])
            ], 10, _hoisted_4)), [
              [
                _directive_tooltip,
                slotProps.data.name,
                void 0,
                { top: true }
              ]
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(slotProps.data.costPerMinute) + " " + _toDisplayString(_ctx.$t('Billing.CostPerMinute')), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["value"]),
      _createElementVNode("div", {
        class: _normalizeClass(["title flex pb-2 justify-content-center", {ops : _ctx.activeId > 0}])
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('Billing.Title2')), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["minute-counter flex flex-wrap justify-content-center align-content-center", {ops : _ctx.activeId > 0}])
      }, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_Button, {
            icon: "pi pi-minus",
            class: _normalizeClass(["blue small", {disabled : _ctx.minutes <= 10 }]),
            disabled: _ctx.minutes <= _ctx.minmin,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.minutes = _ctx.minutes <= _ctx.step ? _ctx.step : _ctx.minutes - _ctx.step))
          }, null, 8, ["class", "disabled"])
        ]),
        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.prev), 1),
        _createVNode(_component_InputNumber, {
          inputClass: "input-minutes",
          modelValue: _ctx.minutes,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.minutes) = $event)),
          min: _ctx.minmin
        }, null, 8, ["modelValue", "min"]),
        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.next), 1),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_Button, {
            icon: "pi pi-plus",
            class: "blue small",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.minutes += _ctx.step))
          })
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["flex justify-content-center align-content-center", {ops : _ctx.activeId > 0}])
      }, [
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.costPerminute) + " " + _toDisplayString(_ctx.$t('Billing.CostPerMinute')), 1)
      ], 2),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('Billing.ToPay')) + " (" + _toDisplayString(_ctx.minutes) + " " + _toDisplayString(_ctx.$t('Time.min')) + ")", 1),
        _createElementVNode("span", _hoisted_20, "$" + _toDisplayString(_ctx.amount), 1)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.cost) + " " + _toDisplayString(_ctx.$t('Billing.CostPerMinute')), 1)
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_Button, {
          type: "button",
          class: "red big",
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.pay()), ["stop"])),
          label: _ctx.$t('Billing.Pay')
        }, null, 8, ["label"])
      ])
    ])
  ], 64))
}