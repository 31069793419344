import { defineComponent, } from 'vue';
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@/utils/i18n-validators";
import DataView from "primevue/dataview";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber"
import { $t } from '@/utils/i18n';
import { BillingService } from '@/api/rest/restClient';
import { TariffPlanDto } from '@/api/rest/serviceTransport';
import store from '@/store';

export default defineComponent({
    components:{
      DataView,
      Button,
      InputNumber
    },
    name: 'Billing',
    props:{
        ms : String
      },
    setup (){
        return { v$: useVuelidate() }

    },
    validations () {
        return {
            email: { required, email },
        }
    },
    data(){
      return {
        activeId : 0,
        minutes : 0,
        step : 1,
        minmin : 1,
        cost : 0.15,
        isError : false,
        progress : true,
        tariffs : [] as TariffPlanDto [],
        tariff : null,
      }
    },
    mounted() {
      this.progress = false
      this.getTariffs() 
      this.cost = store.getters.costPerMinute  
    },
    computed:{
      prev(){
        return this.minutes > this.step ? this.minutes - this.step : this.minutes  
      },
      next(){
        return this.minutes + this.step > 1000000 ? this.minutes : this.minutes + this.step  
      },
      costPerminute(){
        return store.getters.costPerMinute
      },
      amount(){
        return Number((this.minutes * this.cost).toFixed(2))
      }
    },
    methods: {
      formatDate(value: Date ) {
        return new Date(value).toLocaleDateString($t('LocaleString'), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
      },
      formatTime(value: Date ) {
        return new Date(value).toLocaleTimeString($t('LocaleString'));
      },
      formatFullDate(value: Date ) {
        return (value == null || value == undefined) ? 'Нет данных' : 
          this.formatDate(value) + '\n' + this.formatTime(value)
      },
      formatSize(size : number) {
        var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) as unknown as number * 1 + ' ' + [$t('Size.byte'), $t('Size.kilobyte'), $t('Size.megabyte'), $t('Size.gigabyte'), $t('Size.terabyte')][i];
      },
      formatSeconds(seconds : number) {
        var str = new Date(seconds * 1000).toISOString()
        let result = str.match(/([1-9]{1,2}):(\d{2}):(\d{2})|([1-9]{1,2}):(\d{2})|([0]{2}):([1-9]{1})([0]{1})|([0]{2}):([0]{1})([1-9]{1})/g);
        return  result ? result[0] : 'n/a'
      },
      formatAmmount(minutes : number){
        if(minutes < 60) 
          return minutes
        else
          return Number((minutes / 60).toFixed(1));  
      },
      formatAmmountStr(minutes : number){
        if(minutes < 60) 
          return $t('Time.minutes')
        else if ((minutes / 60) == 1) return $t('Time.h1')
        else if ( Math.floor(minutes / 60) <= 4) return $t('Time.h24')
        else if ((minutes % 60) > 0 && Math.floor(minutes / 60) > 4) return $t('Time.h24')
        else return $t('Time.h5')
      },
      toggleError(err : string){
              this.$toast.add({severity:'error', summary: $t('Error'), detail:err, life: 3000});  
      },
      getTariffs(){
        BillingService.getAvailableTariffPlans()
        .then(data => {
          this.tariffs = data as TariffPlanDto[]
          this.progress = false
        })
        .catch(err => this.toggleError(err?.response?.data))
      },
      setActive(id){
        this.activeId = this.activeId == id ? 0 : id
        this.tariff = this.tariffs.find((t) => t.id === this.activeId)
        if(this.tariff){
          this.amount = this.tariff.amount
          this.minutes = this.tariff.minutes
          this.cost = this.tariff.costPerMinute
        }
        else{
          this.cost = this.costPerminute
          this.tariff = null
          this.activeId = 0
        }
      },
      pay(){
        console.log('pay')
        if(this.activeId > 0 ){
          //опалата по тарифному плану
          BillingService.createTransactionFromPlan({planId : this.activeId})
          .then(data => {
            location.href = data.payLink as string
          })
          .catch(err => this.toggleError(err?.response?.data))
        }
        else{
          //оплата по минутам
          BillingService.createTransaction({minutes : this.minutes})
          .then(data => {
            location.href = data.payLink as string
          })
          .catch(err => this.toggleError(err?.response?.data))
        }
        return
      }
    },
    watch:{
      ms:{
        immediate: true,
        handler(val, oldVal) {
          const parsed = parseInt(val);
          if (isNaN(parsed)) { 
            this.minutes = this.step  
            return  }
          this.minutes = parsed
        },
      },
      minutes:{
        immediate: true,
        handler(val, oldVal) {
          if(val > 1000000) this.minutes = 1000000 
        },
      },
    }
  })
